/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { ContentPageLayout } from '@components/Layout';
// @ts-ignore
import featuredImage from '../../resources/images/liinakonkatu.png';
interface ICustomerBenefitsPage {
}

const CustomerBenefitsPage: FC<ICustomerBenefitsPage> = () => {
  return (
    <ContentPageLayout slug="customer-benefits" featuredImageFile={featuredImage} />
  );
};

export default CustomerBenefitsPage;
